<script setup lang="ts">
const { t } = useT();
const isGuest = useIsGuest();

defineProps<{
	image: string;
	coins: number;
	entries: number;
	closeModal?: boolean;
}>();
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 292,
			height: 380,
			src: image,
			format: 'avif',
			alt: 'card invite'
		}"
		width="292px"
		height="380px"
		class="card-invite"
	>
		<template #top>
			<MPromotionHeadlineFont>
				{{ t("Invite friends") }}
			</MPromotionHeadlineFont>
			<div class="text">
				<AText variant="turin" as="div" :modifiers="['medium', 'center']" class="subtitle">{{
					isGuest ? t("Share Rewards with your besties! ") : t("Share the Rewards!")
				}}</AText>
			</div>

			<div class="box">
				<AText
					v-if="coins"
					variant="tunis"
					data-tid="promo-invite-coins"
					:modifiers="['bold', 'uppercase']"
					:class="['count', { single: !entries }]"
				>
					<ASvg name="coins" />
					{{ numberFormat(coins) }}
				</AText>

				<AText
					v-if="entries && !isGuest"
					variant="tunis"
					data-tid="promo-invite-entries"
					:modifiers="['bold', 'uppercase']"
					class="count"
				>
					<ASvg name="entries" />
					<div class="entries-text">
						<span>{{ numberFormat(entries) }}</span>
						<AText :modifiers="['semibold', 'uppercase', 'left']" class="for-free">{{ t("for free") }}</AText>
					</div>
				</AText>
			</div>
		</template>
		<template #bottom>
			<NuxtImg
				src="/nuxt-img/promotion/invite@2x.png"
				width="584"
				height="392"
				class="bg"
				format="avif"
				alt="background invite"
				loading="lazy"
			/>

			<AButton variant="primary" size="xl" class="foot" data-tid="promo-share-link">
				<AText variant="turin" :modifiers="['medium']">{{ t("Copy Link") }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.count {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	gap: 4px;

	&.single {
		margin-bottom: 44px;
	}
}

.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	gap: 4px;
}
.text {
	margin: 8px 0 2px;
}
.foot {
	width: 100%;
	position: relative;
	z-index: 3;
}

.entries-text {
	display: flex;
	gap: 4px;
}

.for-free {
	width: 36px;
}

.bg {
	width: 292px;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	z-index: 2;
}
</style>
